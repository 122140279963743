import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        x: -800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: 800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}


export const InitialPage = () => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                <div className={styles.item}>
                    <div className={styles.overlay}>
                        <div className={styles.container}>
                            <motion.h1 className={styles.title} variants={titleAnimation}>Топ Арт</motion.h1>
                            <motion.p className={styles.text} variants={subtitleAnimation}>Работаем для тех кто хочет быть самим собой
                            </motion.p>
                            <a className={styles.button} href="https://n980818.yclients.com/company/910892/menu?o=" target='_blank' rel='noopener noreferrer'>онлайн запись</a>
                        </div>
                    </div>
                </div>  
        </motion.section>
    )
}