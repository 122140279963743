import React from 'react'
import styles from './OurServices.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/OurServices/img_1.jpg'
import image_2 from '../../image/OurServices/img_2.jpg'
import image_3 from '../../image/OurServices/img_3.jpg'
import image_4 from '../../image/OurServices/img_4.jpg'

export const OurServices = () => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .3 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .6}
        } 
    }

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>АКЦИИ И<span> СКИДКИ</span></motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.image} src={image_1} alt='медицина'/>
                            </div>
                            <div className={styles.box_info}>
                                <h3 className={styles.subtitle}>Отец и сын</h3>
                                <p className={styles.text}>2 500 ₽</p>
                                <a className={styles.button} href="https://n980818.yclients.com/company/910892/menu?o=" target='_blank' rel='noopener noreferrer'>записаться</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.image} src={image_2} alt='медицина'/>
                            </div>
                            <div className={styles.box_info}>
                                <h3 className={styles.subtitle}>Первая стрижка</h3>
                                <p className={styles.text}>скидка 20%</p>
                                <a className={styles.button} href="https://n980818.yclients.com/company/910892/menu?o=" target='_blank' rel='noopener noreferrer' >записаться</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.image} src={image_3} alt='медицина'/>
                            </div>
                            <div className={styles.box_info}>
                                <h3 className={styles.subtitle}>Гидрогелевые патчи</h3>
                                <p className={styles.text}>200 ₽</p>
                                <a className={styles.button} href="https://n980818.yclients.com/company/910892/menu?o=" target='_blank' rel='noopener noreferrer' >записаться</a>
                            </div>
                            
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.image} src={image_4} alt='медицина'/>
                            </div>
                            <div className={styles.box_info}>
                                <h3 className={styles.subtitle}>Cтрижка и борода</h3>
                                <p className={styles.text}>2 600 ₽</p>
                                <a className={styles.button} href="https://n980818.yclients.com/company/910892/menu?o=" target='_blank' rel='noopener noreferrer'>записаться</a>
                            </div>
                        </li>
                    </motion.ul>
            </div>   
        </motion.section>
        
        
    )
}