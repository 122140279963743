import React from 'react'
import app from './App.module.scss'
import {Main} from '../../pages/Main/Main'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'
import { ButtonCommunication } from '../ButtonCommunication/ButtonCommunication'

function App() {

  const vh = window.innerHeight / 100;
  document.documentElement.style.setProperty('--vh', `${vh}px`);



  return (
    <div className={app.page}>
      <Header
      />
      <Main/>
      <Footer/>
      <ButtonCommunication/>
    </div>
  );
}

export default App;
