import React from 'react'
import styles from './Prices.module.scss'
import { Hint } from '../Hint/Hint'
import { arrManicure, arrBrow, arrPedikyur, arrBarber, arrBrandBarber} from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'


export const Prices = () => {


    return (
        <section className={styles.main}>
            <div className={styles.box}>
                <h2 className={styles.title}><span>прайс-лист</span> на услуги</h2>
                <Hint
                    title={'Бренд-Барбер'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrBrandBarber.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
                <Hint
                    title={'Барбер'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrBarber.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
                <Hint
                    title={'Маникюр'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrManicure.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
                <Hint
                    title={'Педикюр'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrPedikyur.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
                <Hint
                    title={'Брови и ресницы'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrBrow.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
            </div>
        </section>
    )
}