import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import { Prices } from '../../components/Prices/Prices'
import { OurServices } from '../../components/OurServices/OurServices'

export const Main = () => {

    return (
        <section className={styles.main}>
            <InitialPage
            />
            <OurServices/>
            <Prices/>
        </section>
    )
}